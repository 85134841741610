<template>
    <div class="skuEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="sku" label-width="80px" :model="sku" :rules="rules" @submit.native.prevent size="small">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="sku.name" />
                </el-form-item>
                <el-form-item label="条码" prop="newBar">
                    <el-input v-model="sku.newBar">
                        <el-button slot="append" icon="el-icon-plus" @click="addBar" size="small" />
                    </el-input>
                </el-form-item>
                <el-form-item prop="bars">
                    <div v-for="(b, idx) in sku.bars" :key="b">
                        <el-input placeholder="条码" :value="b" readonly>
                            <el-button slot="append" icon="el-icon-close" @click="delBar(idx)" size="small"
                                >删除
                            </el-button>
                        </el-input>
                        <br />
                    </div>
                </el-form-item>
                <el-form-item label="类型">
                    <span>{{ computedType }}</span>
                </el-form-item>
                <el-form-item label="规格" prop="specs">
                    <el-input v-model="sku.specs" />
                </el-form-item>
                <el-form-item label="单位(小)" prop="unit">
                    <el-input v-model="sku.unit" :readonly="sku.goodsType === 1" />
                </el-form-item>
                <el-form-item label="类目" prop="categoryCode">
                    <el-select placeholder="类目" v-model="sku.categoryCode" filterable>
                        <el-option-group v-for="group in meta.categories" :label="group.name" :key="group.id">
                            <el-option
                                v-for="category in group.sub"
                                :label="category.name"
                                :value="category.code"
                                :key="category.code"
                            />
                        </el-option-group>
                    </el-select>
                </el-form-item>
                <el-form-item label="品牌" prop="brandCode">
                    <el-select placeholder="品牌" v-model="sku.brandCode" filterable>
                        <el-option v-for="bd in meta.brands" :label="bd.name" :value="bd.code" :key="bd.code" />
                    </el-select>
                </el-form-item>
                <el-form-item label="保质期" prop="expirationDate">
                    <el-input v-model="sku.expirationDate" />
                </el-form-item>
                <el-form-item prop="rst" />
                <el-form-item>
                    <el-button type="primary" @click="save" size="small">保存</el-button>
                    <el-button @click="reset" size="small">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'SkuEdit',
    props: ['form'],
    data() {
        return {
            checkBar: (rule, value, callback) => {
                if (!value || !/^\d{13}|\d{8}|\d{12}$/.test(value.trim())) {
                    return callback(new Error('请输入13位或12位或8位数字'));
                }
                callback();
            },
            checkBarBlank: (rule, value, callback) => {
                callback();
                return;
            },
            url: {
                patch: '/goods/sku/patchExtend/',
            },
            newBar: '',
            sku: {},
            backUp: {
                bars: [],
                code: {},
                name: {},
                specs: {},
                goodsType: {},
                categoryCode: {},
                unit: {},
                brandCode: {},
                expirationDate: {},
            },
            meta: {
                brands: [],
                categories: [],
            },
            rules: {
                newBar: { validator: this.checkBarBlank },
                name: { required: true, message: '请填写物品名称', trigger: 'blur' },
                bars: { required: true, type: 'array', message: '请至少输入一个条码', trigger: 'blur' },
                unit: [{ required: true, message: '请输入单位', trigger: 'blur' }],
                brandCode: [{ required: true, message: '请选择品牌', trigger: 'change' }],
                categoryCode: [{ required: true, message: '请选择类目', trigger: 'change' }],
                expirationDate: [{ required: true, message: '请填写保质期', trigger: 'blur' }],
                specs: [{ required: true, message: '请填写规格', trigger: 'blur' }],
            },
        };
    },
    computed: {
        computedType() {
            if (typeof this.sku.goodsType == 'undefined') {
                return '';
            }
            if (this.sku.goodsType + '' == '0') {
                return '标品';
            }
            if (this.sku.goodsType + '' == '1') {
                return '非标品';
            }
            return '';
        },
    },
    mounted() {
        const _this = this;
        this.backUp.bars = this.form.bars;
        this.backUp.code = this.form.code;
        this.backUp.name = this.form.name;
        this.backUp.specs = this.form.specs;
        this.backUp.goodsType = this.form.type;
        this.backUp.categoryCode = this.form.categoryCode;
        this.backUp.unit = this.form.unit;
        this.backUp.brandCode = this.form.brandCode;
        this.backUp.expirationDate = this.form.expirationDate;

        this.sku = JSON.parse(JSON.stringify(this.backUp));
        UrlUtils.GroupedGoodsCategories(this, (data) => {
            _this.meta.categories = data;
        });
        UrlUtils.GoodsBrands(this, (data) => {
            _this.meta.brands = data;
        });
    },
    methods: {
        addBar() {
            const _this = this;
            this.rules.newBar.validator = this.checkBar;
            this.$refs['sku'].validateField('newBar', (validMessage) => {
                _this.rules.newBar.validator = this.checkBarBlank;
                if (validMessage != '') {
                    return false;
                }
                const _bars = _this.sku.bars;
                _bars.push(_this.sku.newBar);
                _this.sku.bars = _bars;
                _this.sku.newBar = '';
            });
        },
        delBar(index) {
            const _bars = this.sku.bars;
            _bars.splice(index, 1);
            this.sku.bars = _bars;
        },
        save() {
            const _this = this;
            this.$refs.sku.clearValidate();
            this.rules.newBar.validator = _this.checkBarBlank;
            this.$refs['sku'].validate((valid) => {
                if (!valid) {
                    _this.rules.newBar.validator = _this.checkBar;
                    return false;
                }
                delete _this.sku['newBar'];

                // UrlUtils.PatchRemote(this,this.url.patch+this.sku.code,this.sku);
                UrlUtils.PatchRemote(this, this.url.patch + this.sku.code, this.sku, null, () => {
                    _this.$message.success('操作成功');
                    this.goBackAndReload();
                });
            });
        },
        reset() {
            this.sku = JSON.parse(JSON.stringify(this.backUp));
            this.rules.newBar.validator = this.checkBarBlank;
            this.$refs['sku'].validate();
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
